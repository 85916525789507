<template>
  <el-row justify="center" align="middle">
    <el-col :sm="{span: 16, offset: 4}">
      <el-form novalidate @submit.native.prevent="handleFormConfirmSubmit">
        <survey-summary
          v-model="termsConditionAccepted"
          :questions="questions"
          :errors="errors"
          :show-terms-and-conditions="showTermsAndConditions"
          :loading="loading" />
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import SurveySummary from '@/components/signup/survey/SurveySummary';

export default {
  components: {
    SurveySummary
  },
  data() {
    return {
      termsConditionAccepted: true,
      loading: false,
      errors: []
    };
  },
  computed: {
    questions() {
      return this.$store.getters.questions;
    },
    isUserAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    isUserGeneral() {
      return this.$store.getters.userIsGeneral;
    },
    showTermsAndConditions() {
      return this.isUserGeneral || this.isUserAffiliate;
    },
    termsText() {
      if (this.isUserGeneral) return 'Privacy Policy';
      return 'Terms & Conditions';
    }
  },
  methods: {
    handleFormConfirmSubmit() {
      if (this.showTermsAndConditions && !this.termsConditionAccepted) {
        this.showErrorMessage(`You must accept the ${this.termsText} to continue`);
        return false;
      }
      this.errors = [];
      this.loading = true;
      this.$store
        .dispatch('confirmSurvey')
        .then(() => {
          this.$router.push('/signup/survey/finish');
        })
        .catch(e => {
          // TODO: improve error handling
          this.errors = e.response.data._meta.errors;
          this.showErrorMessage('There is an error with your information.');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showErrorMessage(message) {
      this.$message({
        showClose: true,
        message: message,
        type: 'error'
      });
    }
  }
};
</script>
