<template>
  <div class="survey-summary-splash-item">
    <div class="survey-summary-media-icon-object">
      <div class="body-cont">
        <h4 class="body__header">
          {{ question.title }}
        </h4>
        <p v-if="platformLoaded" class="body__content" v-html="summary" />
      </div>
    </div>
    <div class="survey-status-cont" :class="`survey-status-cont-${question.key}`">
      <router-link :to="questionLink">
        edit
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    summary() {
      return this.$store.getters.getQuestionAnswerSummary(this.question);
    },
    questionLink() {
      return `/signup/survey/${this.question.key}`;
    },
    platformLoaded() {
      return !this.$store.state.platform.loading;
    }
  }
};
</script>
<style lang="scss" scoped>
.survey-summary-splash-item {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;

  &:last-child {
    margin-bottom: 15px;
  }
}

.survey-summary-media-icon-object {
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
}

.survey-status-cont a {
  color: $--jb-light-primary-color;
  font-size: $--jb-font-size-small;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.body-cont {
  .body__header {
    color: $--clb-color__headings;
    margin: 0 0 5px;
    text-align: left;
  }

  .body__content {
    color: $--jb-black-primary-color;
    font-size: $--jb-font-size-small;
    line-height: 16px;
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
  }
}
</style>
<style lang="scss">
.survey-summary-splash-item {
  ul,
  ol {
    margin: 0 !important;
    padding-left: 16px !important;

    li {
      list-style: disc;
    }
  }
}
</style>
