<template>
  <div class="survey-summary-component">
    <h1 class="survey-summary-header">
      Summary
    </h1>
    <p class="survey-summary-sub-header">
      We want to make sure we got all of the right info
    </p>
    <div class="summary-card-cont">
      <div class="summary-card">
        <survey-summary-question-item
          v-for="(question, questionKey) in visibleQuestions"
          :key="questionKey"
          :has-error="answerHasError(questionKey)"
          :question="question" />
      </div>
    </div>
    <div :class="{ 'no-height': !showTermsAndConditions }" class="terms-and-conds">
      <el-checkbox
        v-if="showTermsAndConditions"
        size="medium"
        name="summary-checkbox"
        type="checkbox"
        @change="handleTermsConditionInputChange">
        <div class="normal">
          <template v-if="isUserAffiliate">
            If accepted as an affiliate,
          </template>I agree to the
          <a :href="termsHref" target="_blank">{{ termsText }}</a>
        </div>
      </el-checkbox>
    </div>
    <el-button :loading="loading" native-type="submit" class="next-button">
      Confirm 👍
    </el-button>
  </div>
</template>
<script>
import SurveySummaryQuestionItem from './SurveySummaryQuestionItem';
import { AFFILIATE_TERMS_AND_CONDITIONS, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '@/constants/externalLinks';

export default {
  components: {
    SurveySummaryQuestionItem
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    questions: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    showTermsAndConditions: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    visibleQuestions() {
      return this.$store.getters.visibleSummaryQuestions;
    },
    isUserAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    isUserGeneral() {
      return this.$store.getters.userIsGeneral;
    },
    termsText() {
      if (this.isUserGeneral) return 'Privacy Policy';
      return 'Terms & Conditions';
    },
    termsHref() {
      if (this.isUserAffiliate) return AFFILIATE_TERMS_AND_CONDITIONS;
      if (this.isUserGeneral) return PRIVACY_POLICY;
      return TERMS_AND_CONDITIONS;
    }
  },
  mounted() {
    this.$emit('input', false);
  },
  methods: {
    handleTermsConditionInputChange(event) {
      this.$emit('input', event);
    },
    answerHasError(questionKey) {
      return this.errors.indexOf(questionKey) >= 0;
    }
  }
};
</script>

<style lang="scss">
.survey-summary-component {
  margin: 24px 0 0;

  .survey-summary-header {
    margin-bottom: 0;
    margin-top: 0;
  }

  .survey-summary-sub-header {
    font-size: $--jb-font-size-small;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  .summary-card-cont {
    border-radius: 4px 4px 0 0;
    position: relative;
  }

  .summary-card {
    background-color: $--jb-bg-primary-color;
    border: 1px solid $--color-primary;
    border-bottom: none;
    border-radius: 4px 2px 0 0;
    max-height: 50vh;
    max-height: calc(50 * var(--vh, 1vh));
    overflow-y: scroll;
    padding: 5px 24px 24px;

    @media (max-width: $--sm) {
      max-height: 40vh;
      max-height: calc(40 * var(--vh, 1vh));
    }
  }

  .next-button {
    margin-top: 20px;
  }

  .terms-and-conds {
    background: linear-gradient(to top, #eff1fd, white);
    border: 1px solid $--color-primary;
    border-radius: 0 0 4px 4px;
    border-top: 1px #ccc solid;
    margin-bottom: 12px;
    padding: 15px 24px;

    .normal {
      color: $--color-text-regular !important;

      a {
        white-space: nowrap;
      }
    }

    &.no-height {
      padding: 0;
    }

    label {
      display: flex;
      white-space: normal;
    }
  }
}
</style>
